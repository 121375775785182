import {Box, Button, HStack, IconButton} from '@chakra-ui/react';
import {useCapture} from '@/posthog/PosthogSetup';
import {useTranslation} from 'react-i18next';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {capitalizeFirstLetter, getDerniersDouzesMois} from 'ff-common';
import {ChevronLeftIcon} from '../../../components/svg/ChevronLeftIcon';
import {ChevronRightBlueIcon} from '../../../components/svg/ChevronRightBlueIcon';
import {Periode} from './ProduitsParSaison';
import {endOfMonth, format, parseISO, startOfMonth} from 'date-fns';
import {fr} from 'date-fns/locale';
import {useHorizontalScroll} from '@/hooks/useHorizontalScroll';

interface MonthSelectorProps {
  selectedPeriod: Periode | undefined;
  onPeriodChange: (period: Periode | undefined) => void;
  isDisabled?: boolean;
}

// Obtenir le mapping des 12 derniers mois
const lastMonthsMapping = getDerniersDouzesMois();

/**
 * Sélecteur de mois avec défilement horizontal et sélection multiple par glissement
 * 
 * En interne, le composant travaille avec des IDs de mois (de 1 à 12, ou "tout"),
 * mais les expose sous forme de périodes avec des dates réelles (date_debut et date_fin).
 * - date_debut est le premier jour du mois sélectionné
 * - date_fin est le dernier jour du mois sélectionné
 */
export const MonthSelector: React.FC<MonthSelectorProps> = ({selectedPeriod, onPeriodChange, isDisabled = false}) => {
  const {t} = useTranslation();
  const capture = useCapture();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isSelectionDrag, setIsSelectionDrag] = useState(false);
  const [draggedMonths, setDraggedMonths] = useState<Set<string>>(new Set());
  const [mouseDownTime, setMouseDownTime] = useState<number>(0);
  // État interne pour suivre les mois sélectionnés par leur ID (pas par date)
  // startMonth et endMonth sont des IDs de mois (de 1 à 12, ou undefined)
  const [internalSelectedMonths, setInternalSelectedMonths] = useState<{startMonth?: string; endMonth?: string}>({});
  
  // Utiliser le hook de défilement horizontal
  const {canScrollLeft, canScrollRight, scrollToStart, scrollToEnd} 
    = useHorizontalScroll(scrollRef);
  
  // Fonction pour sélectionner tous les mois
  const selectAllMonths = useCallback(() => {
    const firstMonthId = '1'; // Premier mois dans notre liste (le plus ancien)
    const lastMonthId = '12'; // Dernier mois dans notre liste (le plus récent)
    
    setInternalSelectedMonths({startMonth: firstMonthId, endMonth: lastMonthId});
    const allMonthsPeriod = monthsToDateRange(firstMonthId, lastMonthId);
    if (allMonthsPeriod) {
      capture({
        name: 'liste_saisonnieres:clic_bouton_mois',
        props: {
          mois: 'tout',
          date_debut: allMonthsPeriod.date_debut,
          date_fin: allMonthsPeriod.date_fin,
        }
      });
      onPeriodChange(allMonthsPeriod);
    }
  }, [capture, onPeriodChange]);

  const selectFirstMonth = useCallback(() => {
    const firstMonthId = '1'; // Premier mois dans notre liste (le plus ancien)

    setInternalSelectedMonths({startMonth: firstMonthId, endMonth: firstMonthId});
    const firstMonthPeriod = monthsToDateRange(firstMonthId, firstMonthId);
    if (firstMonthPeriod) {
      onPeriodChange(firstMonthPeriod);
    }
  }, [onPeriodChange]);
  
  // Liste des mois avec leur traduction et intégration des mois réels
  const months = useMemo(() => {
    return [
      {id: 'tout', name: t('liste.mois.tout')},
      ...Array(12).fill(null).map((_, index) => {
        const monthId = (index + 1).toString();
        const info = lastMonthsMapping[monthId];
        // Créer une date pour ce mois et utiliser date-fns pour formater avec un nom de mois court
        const monthDate = new Date(info.year, info.monthNumber - 1, 1);
        return {
          id: monthId,
          name: capitalizeFirstLetter(format(monthDate, 'MMM yyyy', {locale: fr}))
        };
      })
    ];
  }, [t]); // Dependency on translation function

  // Par default, sélectionner le premier mois si non désactivé
  useEffect(() => {
    if (!isDisabled && !selectedPeriod) {
      selectFirstMonth();
    }
  }, [isDisabled, selectFirstMonth, selectedPeriod]);

  // Nettoyage des événements globaux
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isDragging) {
        finishDragSelection();
      }
    };

    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, draggedMonths, isSelectionDrag]);

  // Gestion du glissement pour sélection multiple
  // Conversion d'un ID de mois en nombre pour comparaison
  const monthToNumber = (monthId: string): number => {
    return monthId === 'tout' ? -1 : parseInt(monthId, 10);
  };

  // Initialisation de l'état interne à partir des dates sélectionnées
  useEffect(() => {
    if (!selectedPeriod) {
      // Si aucune période n'est sélectionnée, réinitialiser l'état interne
      if (internalSelectedMonths.startMonth ?? internalSelectedMonths.endMonth) {
        setInternalSelectedMonths({});
      }
      return;
    }
    
    // Convertir les dates en IDs de mois pour l'état interne
    const startMonthId = dateToMonthId(selectedPeriod.date_debut);
    const endMonthId = dateToMonthId(selectedPeriod.date_fin);
    
    // Uniquement mettre à jour l'état interne si les mois ont changé
    // Cela évite une boucle infinie de mise à jour
    if (startMonthId !== internalSelectedMonths.startMonth || 
        endMonthId !== internalSelectedMonths.endMonth) {
      setInternalSelectedMonths({
        startMonth: startMonthId,
        endMonth: endMonthId
      });
    }
  }, [selectedPeriod, internalSelectedMonths]);

  // Vérifie si un mois est sélectionné (dans la période)
  const isMonthInPeriod = (monthId: string): boolean => {
    // Si "tout" est sélectionné, tous les mois sont considérés comme sélectionnés
    if (monthId === 'tout') {
      // On vérifie si la période actuelle couvre tous les mois
      const allMonthsSelected = 
        internalSelectedMonths.startMonth === '1' && 
        internalSelectedMonths.endMonth === '12';
      return allMonthsSelected;
    }
    
    // Si aucun mois n'est sélectionné
    if (internalSelectedMonths.startMonth === undefined || internalSelectedMonths.endMonth === undefined) {
      return false;
    }
    
    const monthNum = monthToNumber(monthId);
    const startNum = monthToNumber(internalSelectedMonths.startMonth);
    const endNum = monthToNumber(internalSelectedMonths.endMonth);
    
    return monthNum >= Math.min(startNum, endNum) && monthNum <= Math.max(startNum, endNum);
  };

  const handleMouseDown = (monthId: string) => {
    if (isDisabled) return;
    
    setMouseDownTime(Date.now());
    
    if (monthId === 'tout') {
      // Sélectionner tous les mois au lieu de désélectionner
      selectAllMonths();
      return;
    }

    setIsDragging(true);
    setIsSelectionDrag(!isMonthInPeriod(monthId));
    setDraggedMonths(new Set([monthId]));
  };

  const handleMouseOver = (monthId: string) => {
    if (isDragging && monthId !== 'tout') {
      setDraggedMonths(prev => {
        const next = new Set(prev);
        next.add(monthId);
        return next;
      });
    }
  };

  const finishDragSelection = () => {
    const draggedArray = Array.from(draggedMonths);
    if (draggedArray.length > 1) { // Si on a draggé sur plusieurs mois
      // Trouver le min et max des mois sélectionnés pour créer une période continue
      const monthNumbers = draggedArray.map(m => parseInt(m, 10)).filter(n => !isNaN(n));
      if (monthNumbers.length > 0) {
        const minMonth = Math.min(...monthNumbers).toString();
        const maxMonth = Math.max(...monthNumbers).toString();
        
        // Si c'est une sélection (et non désélection), on définit la nouvelle période
        if (isSelectionDrag) {
          updatePeriod(minMonth, maxMonth);
        } else {
          // Pour une désélection, sélectionner le dernier mois par défaut
          selectFirstMonth();
        }
      }
    }
    // Pour un seul mois (clic), on laisse le handleMouseUp gérer
    resetDragState();
  };

  // Helper function to update state, capture event, and call onPeriodChange
  const updatePeriod = (startMonth: string, endMonth: string) => {
    setInternalSelectedMonths({startMonth, endMonth});
    const period = monthsToDateRange(startMonth, endMonth);
    if (period) {
      // Get formatted month name instead of using date string
      const monthDate = monthIdToDate(startMonth)?.start;
      const monthName = startMonth === endMonth
        ? (startMonth === 'tout' 
          ? 'tout' 
          : monthDate 
            ? capitalizeFirstLetter(format(monthDate, 'MMM yyyy', {locale: fr}))
            : startMonth)
        : 'periode';
            
      capture({
        name: 'liste_saisonnieres:clic_bouton_mois',
        props: {
          mois: monthName,
          date_debut: period.date_debut,
          date_fin: period.date_fin,
        }
      });
      onPeriodChange(period);
    }
  };

  const handleMouseUp = (monthId: string) => {
    const mouseUpTime = Date.now();
    const isClick = mouseUpTime - mouseDownTime < 200;

    if (isClick || draggedMonths.size <= 1) {
      // Simple clic ou drag très court
      if (monthId === 'tout') {
        // Sélectionner tous les mois
        selectAllMonths();
      } else {
        // Si aucune période n'est sélectionnée ou si on clique sur un mois non-connecté
        // à la période existante, on commence une nouvelle période
        if (internalSelectedMonths.startMonth === undefined || internalSelectedMonths.endMonth === undefined) {
          // Premier mois sélectionné - début et fin identiques
          updatePeriod(monthId, monthId);
        } else {
          const monthNum = parseInt(monthId, 10);
          const startNum = parseInt(internalSelectedMonths.startMonth, 10);
          const endNum = parseInt(internalSelectedMonths.endMonth, 10);
          
          // Vérifier si le mois est adjacent ou à l'intérieur de la période existante
          if (monthNum >= startNum - 1 && monthNum <= endNum + 1) {
            // Ajustement de la période existante
            if (monthNum < startNum) {
              // Ajouter un mois avant la période
              updatePeriod(monthId, internalSelectedMonths.endMonth);
            } else if (monthNum > endNum) {
              // Ajouter un mois après la période
              updatePeriod(internalSelectedMonths.startMonth, monthId);
            } else if (monthNum === startNum && startNum !== endNum) {
              // Clic sur le premier mois : réduire la période en avançant le début d'un mois
              const newStart = (startNum + 1).toString();
              updatePeriod(newStart, internalSelectedMonths.endMonth);
            } else if (monthNum === endNum && startNum !== endNum) {
              // Clic sur le dernier mois : réduire la période en reculant la fin d'un mois
              const newEnd = (endNum - 1).toString();
              updatePeriod(internalSelectedMonths.startMonth, newEnd);
            } else if (monthNum > startNum && monthNum < endNum) {
              // Clic sur un mois au milieu : ne sélectionner que ce mois
              updatePeriod(monthId, monthId);
            } else {
              // Si aucun mois n'est sélectionné, sélectionner le dernier mois par défaut
              selectFirstMonth();
            }
          } else {
            // Mois non-connecté - annuler la sélection existante et commencer une nouvelle
            updatePeriod(monthId, monthId);
          }
        }
      }
    } else if (isDragging) {
      // Fin d'un vrai drag
      finishDragSelection();
    }

    resetDragState();
  };

  const resetDragState = () => {
    setIsDragging(false);
    setDraggedMonths(new Set());
  };

  const isMonthHighlighted = (monthId: string) => {
    if (!isDragging) {
      return isMonthInPeriod(monthId);
    }
    
    // Pendant le drag, on montre la sélection temporaire
    if (draggedMonths.has(monthId)) {
      return isSelectionDrag;
    }
    
    return isMonthInPeriod(monthId);
  };

  return (
    <Box position="relative"
      width="100%"
      onMouseLeave={resetDragState}
      opacity={isDisabled ? 0.6 : 1}
      pointerEvents={isDisabled ? 'none' : 'auto'}
      cursor={isDisabled ? 'not-allowed' : 'auto'}>
      {/* Chevron de défilement gauche */}
      {canScrollLeft && (
        <IconButton
          aria-label="Défiler vers la gauche"
          icon={<ChevronLeftIcon />}
          position="absolute"
          left={0}
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
          bg="white"
          borderRadius="full"
          boxShadow="md"
          onClick={scrollToStart}
          size="sm"
        />
      )}

      {/* Chevron de défilement droit */}
      {canScrollRight && (
        <IconButton
          aria-label="Défiler vers la droite"
          icon={<ChevronRightBlueIcon />}
          position="absolute"
          right={0}
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
          bg="white"
          borderRadius="full"
          boxShadow="md"
          onClick={scrollToEnd}
          size="sm"
        />
      )}
      
      <HStack 
        ref={scrollRef}
        spacing={2} 
        overflowX="auto" 
        py={2}
        px={3}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          scrollbarWidth: 'none'
        }}
      >
        {months.map((month) => (
          <Button
            key={month.id}
            size={{base: 'sm', md: 'md'}}
            variant="outline"
            onMouseDown={() => {handleMouseDown(month.id);}}
            onMouseOver={() => {handleMouseOver(month.id);}}
            onMouseUp={() => {handleMouseUp(month.id);}}
            isActive={isMonthHighlighted(month.id)}
            userSelect="none"
            borderColor={isMonthHighlighted(month.id) ? 'blue.main' : 'gray.light'}
            color={isMonthHighlighted(month.id) ? 'blue.main' : 'gray.dark'}
            bg={isMonthHighlighted(month.id) ? 'blue.main_transparency_5' : 'white'}
            _hover={{
              bg: isMonthHighlighted(month.id) ? 'blue.main_transparency_10' : 'gray.50'
            }}
            _active={{
              bg: 'blue.main_transparency_10',
              borderColor: 'blue.main'
            }}
            flexShrink={0}
          >
            {month.name}
          </Button>
        ))}
      </HStack>
    </Box>
  );
};

// Fonction utils pour convertir les IDs internes en dates et vice-versa

const monthIdToDate = (monthId: string): {start: Date; end: Date} | undefined => {
  if (monthId === 'tout' || !monthId) return undefined;
  
  if (!(monthId in lastMonthsMapping)) return undefined;
  const monthInfo = lastMonthsMapping[monthId];
  
  const date = new Date(monthInfo.year, monthInfo.monthNumber - 1, 1); // Les mois commencent à 0 en JS
  return {
    start: startOfMonth(date),
    end: endOfMonth(date)
  };
};

const dateToMonthId = (dateStr?: string): string | undefined => {
  if (!dateStr) return undefined;
  
  const date = parseISO(dateStr);
  // Chercher le mois correspondant dans le mapping
  for (const [monthId, info] of Object.entries(lastMonthsMapping)) {
    if (info.monthNumber === date.getMonth() + 1 && info.year === date.getFullYear()) {
      return monthId;
    }
  }
  return undefined;
};

/**
 * Convertit des IDs de mois en objet Periode avec dates réelles ou undefined
 */
const monthsToDateRange = (startMonthId?: string, endMonthId?: string): Periode | undefined => {
  if (startMonthId === 'tout' || endMonthId === 'tout' || !startMonthId || !endMonthId) {
    return undefined;
  }
  
  const startDateRange = monthIdToDate(startMonthId);
  const endDateRange = monthIdToDate(endMonthId);
  
  if (!startDateRange || !endDateRange) {
    return undefined;
  }
  
  return {
    date_debut: format(startDateRange.start, 'yyyy-MM-dd'),
    date_fin: format(endDateRange.end, 'yyyy-MM-dd')
  };
};