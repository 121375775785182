import {Box, HStack, Select, SelectProps, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {SortType} from './TriArticlesCadencier';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {useHalState} from '@synako/haligator';
import {useClient} from '@/haligator-factory';
import {useCapture} from '@/posthog/PosthogSetup';

interface TriSectionsCadencierProps {
  value: SortType;
  onChange: (value: SortType) => void;
}

// Composant pour sélectionner le type de tri des articles par fréquence d’achat (suggestions) ou ordre alphabétique
export const TriSectionsCadencier: React.FC<TriSectionsCadencierProps> = ({ 
  value, 
  onChange,
}) => {
  const {t} = useTranslation();
  const client  = useClient();
  const {state: suggestionsState} = useHalState(client.follow('suggestions'));
  const capture = useCapture();

  const handleChange: SelectProps['onChange'] = (event) => {
    const newValue = event.target.value as SortType;
    onChange(newValue);
    // Track the sort selection
    capture({
      name: 'cadencier:tri',
      props: {
        par: newValue
      }
    });
  };

  value = !suggestionsState?.data.suggestions.length ? 'alphabetic' : value;
  
  return (
    <Box width="230px"
      mr='10px'>
      <HStack
        bg="white"
        border="1px solid"
        borderColor="blue.main"
        borderRadius="8px"
        p={1}
      >
        <Text color="blue.main"
          fontWeight='700'
          fontSize='sm'
          width='40px'
          bg='white'>{t('cadencier.tri.tri')}</Text>
        <Select
          value={value}
          onChange={handleChange}
          variant="unstyled"
          color="blue.main"
          fontSize='sm'
          disabled={!suggestionsState?.data.suggestions.length}
          icon={<ChevronDownIcon color="blue.main" />}
        >
          <option value="alphabetic">{t('cadencier.tri.alphabetique')}</option>
          <option value="frequency">{t('cadencier.tri.frequence')}</option>
        </Select>
      </HStack>
    </Box>
  );
};
